import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { format } from "date-fns-tz";

import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

const StyledModal = styled(Modal)`
  .modal-content {
    width: 366px;
    border-radius: 8px;
    background-color: #323232;
  }

  @media (max-width: 768px) {
    .modal-content {
      width: calc(100vw - 20px);
      max-width: 366px;
      height: auto;
    }
  }
`;

const ModalBody = styled.div`
  padding: 16px;
`;

const Message = styled.div`
  color: #fff;
  font-size: 16px;
  line-height: 22px;
`;

const Bold = styled.b``;

const ModalBodyButtonWrap = styled.div`
  margin-top: 16px;
`;

const ModalBodyButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #202020;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: bold;

  &:active,
  &:hover {
    opacity: 0.8;
  }
`;

function SuccessAlertModal() {
  const { ...actions } = useModalStore();

  const closeModal = useCallback(() => {
    actions.closeModal();
  }, [actions.closeModal]);

  return (
    <StyledModal backdrop="static">
      <ModalBody>
        <Message>
          <Bold>({format(new Date(), "Y년 M월 d일 H:m")})</Bold> 배송CS가
          등록되었습니다. 해당 내용 배송기사에게 전달하여 빠르게 조치하겠습니다.
          가장 빠른 오늘 도착 택배 착을 이용해주셔서 감사하며 고객사와 함께 더
          빠르고 안전하게 배송할 수 있도록 노력하겠습니다. 즐거운 하루
          보내십시오!
        </Message>
        <ModalBodyButtonWrap>
          <ModalBodyButton type="button" onClick={closeModal}>
            닫기
          </ModalBodyButton>
        </ModalBodyButtonWrap>
      </ModalBody>
    </StyledModal>
  );
}

export default SuccessAlertModal;
