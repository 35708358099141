import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import "bootstrap/dist/css/bootstrap.min.css";

import Main from "./pages/index";

import { DeliveryStore, ModalStore, NavStore } from "./stores";

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children
  );

function App() {
  return (
    <AppProvider contexts={[QueryParamProvider, DeliveryStore.Provider, ModalStore.Provider, NavStore.Provider]}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="*">
            <Redirect to="/"></Redirect>
          </Route>
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
