import { differenceInMinutes } from "date-fns";
import { format } from "date-fns-tz";

export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";

export class Delivery {
  accidentAt = null;
  bookId = "";
  canceledAt = null;
  corpUser = null;
  cs = null;
  deliveryCompletedDate = null;
  etc4 = "{}";
  notReceivedImageLocation = null;
  receiverAddress = "";
  receiverAddressDetail = "";
  receiverAddressRoad = "";
  receiverName = null;
  releasedAt = null;
  rider = null;
  senderName = null;
  signImageLocation = null;
  status = DELIVERY_STATUS_0;

  constructor(delivery) {
    this.bookId = delivery.bookId;

    switch (Number(delivery.status)) {
      case 0:
        this.status = DELIVERY_STATUS_0;
        break;
      case 1:
        this.status = DELIVERY_STATUS_1;
        break;
      case 2:
        this.status = DELIVERY_STATUS_2;
        break;
      case 3:
        this.status = DELIVERY_STATUS_3;
        break;
      case 4:
        this.status = DELIVERY_STATUS_4;
        break;
      case 5:
        this.status = DELIVERY_STATUS_5;
        break;
      case 6:
        this.status = DELIVERY_STATUS_6;
        break;
      default:
        throw new Error("잘못된 배송상태값입니다.");
    }

    this.accidentAt = delivery.accidentAt;
    this.canceledAt = delivery.canceledAt;
    this.corpUser = delivery.corpUser;
    this.cs = delivery.cs;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.etc4 = delivery.etc4;
    this.notReceivedImageLocation = delivery.notReceivedImageLocation;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressDetail = delivery.receiverAddressDetail;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverName = delivery.receiverName;
    this.releasedAt = delivery.releasedAt;
    this.rider = delivery.rider;
    this.senderName = delivery.senderName;
    this.signImageLocation = delivery.signImageLocation;
  }

  get completedLocation() {
    return this.etc4Obj ? this.etc4Obj.completedLocation : "";
  }

  get csFromCorp() {
    if (this.cs && this.cs.length > 0) {
      return this.cs.find((e) => !!e.userId) > 0;
    } else {
      return false;
    }
  }

  get csFromUser() {
    if (this.cs && this.cs.length > 0) {
      return this.cs.find((e) => !e.userId) > 0;
    } else {
      return false;
    }
  }

  get displayCompletedDate() {
    if (this.deliveryCompletedDate) {
      return format(new Date(this.deliveryCompletedDate), "Y년 M월 d일 H:m");
    } else {
      return "";
    }
  }

  get displayEncodedReceiverName() {
    if (this.receiverName && this.receiverName.length) {
      let first = this.receiverName.substring(0, 1);
      let encoded = [];
      for (let i = 1; i < this.receiverName.length; i++) {
        encoded.push("*");
      }

      return first + encoded.join("");
    } else {
      return "";
    }
  }

  get displaySenderName() {
    return this.corpUser ? this.corpUser.title : this.senderName;
  }

  get displayTime() {
    let time = 0;

    if (this.releasedAt && this.deliveryCompletedDate) {
      time = differenceInMinutes(
        new Date(this.deliveryCompletedDate),
        new Date(this.releasedAt),
      );
    }

    return `${parseInt(time / 60)}시간 ${time % 60}분`;
  }

  get errorStatusMessage() {
    const elapsedDate = this.deliveryCompletedDate
      ? differenceInMinutes(new Date(), new Date(this.deliveryCompletedDate)) /
        1440
      : 0;

    if (this.accidentAt) {
      return "해당 접수건은 사고 상태로 배송문제를 등록하실 수 없습니다. 착배송 혹은 구매처로 문의 부탁드립니다. 감사합니다.";
    } else if (this.canceledAt) {
      return "해당 접수건은 취소 상태로 배송문제를 등록하실 수 없습니다. 착배송 혹은 구매처로 문의 부탁드립니다. 감사합니다.";
    } else if (
      this.status !== DELIVERY_STATUS_5 ||
      !this.deliveryCompletedDate
    ) {
      return "해당 접수건은 배송완료 상태가 아닙니다. 착배송 혹은 구매처로 문의 부탁드립니다. 감사합니다.";
    } else if (this.csFromUser) {
      return "이미 배송문제를 등록하셨습니다. 최대한 빨리 확인 후 조치하겠습니다. 다른 문의가 있으신 경우 카카오톡 착배송 채널을 통해 상담 신청 부탁드립니다.";
    } else if (elapsedDate > 30) {
      return "배송문제 등록 기간이 지나 이용하실 수 없습니다. 더 빠르고 안전하게 배송하겠습니다.";
    } else {
      return null;
    }
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get image() {
    if (this.etc4Obj && this.etc4Obj.signImageLocation) {
      return this.etc4Obj.signImageLocation;
    } else if (this.etc4Obj && this.etc4Obj.notReceivedImageLocation) {
      return this.etc4Obj.notReceivedImageLocation;
    } else if (this.signImageLocation) {
      return this.signImageLocation;
    } else if (this.notReceivedImageLocation) {
      return this.notReceivedImageLocation;
    } else {
      return null;
    }
  }

  get riderMobile() {
    return this.rider ? this.rider.mobile : "";
  }

  get riderName() {
    return this.rider ? this.rider.name : "";
  }
}
