import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { withQueryParams, StringParam } from "use-query-params";

import { useDeliveryStore, useModalStore, useNavStore } from "../stores/hooks";

import Footer from "../components/Footer";
import Nav from "../components/Nav";
import SuccessAlertModal from "../modals/SuccessAlertModal";
import PrivacyModal from "../modals/PrivacyModal";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen &&
    `
      position: fixed;
      width: 100%;
      height: 100vh;
    `}
`;

const BgTriangle = styled.div`
  width: 100%;
  height: 190px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 30px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 160px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const SectionTitle = styled.div`
  padding-bottom: 32px;
`;
const PreTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

const Highlight = styled.span`
  color: #75ff6f;
`;

const SectionBody = styled.div``;
const SectionIntro = styled.div`
  padding-top: 14px;
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #333;
`;

const SectionDelivery = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #333;
`;
const SectionDeliveryImageWrap = styled.div`
  margin-top: 8px;
`;
const SectionDeliveryImage = styled.img`
  width: 100%;
`;

const SectionForm = styled.div`
  padding-top: 24px;
`;
const SectionFormTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
`;
const TextWrap = styled.div`
  position: relative;
`;
const Textarea = styled.textarea`
  resize: none;
  height: 240px;
  background-color: #333;
  padding: 13px 10px;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  display: block;

  &::placeholder {
    color: #999;
  }
`;
const TextCount = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
  color: #999;
`;

const FileWrap = styled.div`
  margin-bottom: 24px;
`;

const InputFile = styled.input`
  display: none;
`;

const ButtonFile = styled.button`
  width: 100%;
  height: 40px;
  background-color: #333;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  border: none;
`;

const Preview = styled.div`
  width: 110px;
  height: 76px;
  background-color: #999;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    props.src &&
    `
    background-image: url(${props.src});
  `}
`;
const DeleteFile = styled.div`
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/icon-delete.png);
  position: absolute;
  top: 1.5px;
  right: 1.5px;
`;

const CsCheckbox = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/checkbox-off.png);
  margin-right: 10px;

  ${(props) =>
    props.active &&
    `
    background-image: url(/assets/images/checkbox-on.png);
  `}
`;

const CheckboxLabel = styled.span``;

const ButtonSubmit = styled.button`
  width: 100%;
  height: 60px;
  background-color: #75ff6f;
  font-size: 22px;
  line-height: 1;
  font-weight: bold;
  color: #202020;
  border: none;
`;

function Index({ query }) {
  const { token } = query;

  const { body, delivery } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { openModal } = useModalStore();
  const { modals } = useModalStore().state;

  const { navActive } = useNavStore().state;

  const inputFileRef = useRef();

  useEffect(() => {
    fetchByToken();
  }, []);

  useEffect(() => {
    if (delivery.errorStatusMessage) {
      alert(delivery.errorStatusMessage);
    }
  }, [delivery]);

  const fetchByToken = useCallback(async () => {
    if (token) {
      try {
        await actions.fetchByToken({ token });
      } catch (e) {
        if (e.status > 500) {
          alert("배송정보를 가져오는데 실패했습니다. 다시 시도해주세요.");
        } else {
          alert(
            "배송정보를 가져올 수 없습니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.",
          );
        }
      }
    } else {
      alert("잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.");
    }
  }, [actions.fetchByToken, token]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "text" && e.target.value) {
        const text = e.target.value;

        if (text.length <= 500) {
          actions.setBody({
            [e.target.name]: e.target.value,
          });
        } else {
          alert("500자로 입력해주세요.");
        }
      } else {
        actions.setBody({
          [e.target.name]: e.target.value,
        });
      }
    },
    [actions.setBody],
  );

  const handleChangeFile = useCallback(
    (e) => {
      if (e.target.files) {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onloadend = () => {
          actions.setBody({
            file: e.target.files[0],
            filePreview: reader.result,
          });
        };
      }
    },
    [actions.setBody],
  );

  const handelClickFile = useCallback(() => {
    inputFileRef.current.click();
  }, [inputFileRef]);

  const handelDeleteFile = useCallback(() => {
    actions.setBody({
      file: null,
      filePreview: null,
    });
  }, [actions.setBody]);

  const handleToggleTerms = useCallback(() => {
    actions.setBody({
      terms: !body.terms,
    });
  }, [actions.setBody, body.terms]);

  const handleChangeTerms = useCallback(
    (checked) => {
      actions.setBody({
        terms: checked,
      });
    },
    [actions.setBody],
  );

  let submitting = false;
  const handleSubmit = useCallback(async () => {
    let _body = { ...body };

    if (!token || !delivery.bookId) {
      alert("잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.");
    } else if (!_body.text) {
      alert("배송문제 내용을 작성 후 등록해주십시오.");
    } else if (!_body.terms) {
      alert("개인정보 수집 및 이용 동의에 체크해주세요.");
    } else if (delivery.errorStatusMessage) {
      alert(delivery.errorStatusMessage);
    } else if (!submitting) {
      submitting = true;

      try {
        let formData = new FormData();

        formData.append("text", _body.text);
        formData.append("file", _body.file);

        await actions.createCs(token, formData);

        openModal(<SuccessAlertModal key="success-alert-modal" />);

        actions.resetBody();
        submitting = false;
      } catch (e) {
        if (e.message) {
          alert(e.message);
        } else {
          alert("배송 문제 작성에 실패했습니다. 다시 시도해주세요.");
        }

        submitting = false;
      }
    }
  }, [
    actions.createReview,
    actions.resetBody,
    body,
    delivery,
    token,
    openModal,
  ]);

  const handelClickPrivacyModal = useCallback(() => {
    openModal(<PrivacyModal key="privacy-modal" onCheck={handleChangeTerms} />);
  }, [openModal]);

  return (
    <BodyContent navOpen={navActive}>
      <BgTriangle />
      <Nav />
      <SectionTitle>
        <Container>
          <PreTitle>더욱 발전하는 착배송이 되겠습니다.</PreTitle>
          <Title>
            배송문제를 <br />
            등록해주세요!
          </Title>
        </Container>
      </SectionTitle>
      <SectionBody>
        <Container>
          <SectionIntro>
            {delivery.receiverName} 고객님, 아래{" "}
            <Highlight>배송완료 내용을 확인</Highlight>하신 후 오배송 등{" "}
            <Highlight>문제상황을 알려주시면</Highlight> 해당 내용을 배송기사
            통해서 최대한 빨리 확인하여 조치하겠습니다.
          </SectionIntro>
          <SectionDelivery>
            ■ 구매처(보내신분) :{" "}
            <Highlight>{delivery.displaySenderName}</Highlight>
            <br />■ 받는분 : <Highlight>{delivery.receiverName}</Highlight>
            <br />■ 수령지 주소 :{" "}
            <Highlight>
              {delivery.receiverAddress || delivery.receiverAddressRoad || ""}{" "}
              {delivery.receiverAddressDetail}
            </Highlight>
            <br />■ 접수번호 : <Highlight>{delivery.bookId}</Highlight>
            <br />■ 배송원 :{" "}
            <Highlight>착배송 배송기사 ☏{delivery.riderMobile}</Highlight>
            <br />■ 배송완료일시 :{" "}
            <Highlight>{delivery.displayCompletedDate}</Highlight>
            <br />■ 배송보관장소(택배함비밀번호) :{" "}
            <Highlight>{delivery.completedLocation}</Highlight>
            <br />■ 서명/보관장소 사진
            <SectionDeliveryImageWrap>
              {delivery.image && (
                <SectionDeliveryImage
                  src={delivery.image}
                  alt={`${delivery.bookId} 배송완료 사진`}
                />
              )}
            </SectionDeliveryImageWrap>
          </SectionDelivery>
          <SectionForm>
            <SectionFormTitle>배송 문제 내용을 입력하세요.</SectionFormTitle>
            <TextWrap>
              <Textarea
                onChange={handleChange}
                value={body.text}
                name="text"
                placeholder="내용을 입력해주세요."
              />
              <TextCount>({body.text.length}/500)</TextCount>
            </TextWrap>
            <FileWrap>
              {body.file && body.filePreview ? (
                <Preview src={body.filePreview}>
                  <DeleteFile onClick={handelDeleteFile} />
                </Preview>
              ) : (
                <>
                  <InputFile
                    accept="image/*"
                    ref={inputFileRef}
                    type="file"
                    name="file"
                    onChange={handleChangeFile}
                  />
                  <ButtonFile type="button" onClick={handelClickFile}>
                    사진 추가하기
                  </ButtonFile>
                </>
              )}
            </FileWrap>
            <CsCheckbox>
              <Checkbox active={body.terms} onClick={handleToggleTerms} />
              <CheckboxLabel onClick={handelClickPrivacyModal}>
                개인정보 수집 및 이용 동의(필수)
              </CheckboxLabel>
            </CsCheckbox>
            <ButtonSubmit type="button" onClick={handleSubmit}>
              배송 문제 등록하기
            </ButtonSubmit>
          </SectionForm>
        </Container>
      </SectionBody>
      <Footer />

      {modals.map((modal) => modal)}
    </BodyContent>
  );
}

export default withQueryParams({ token: StringParam }, Index);
